import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, shareReplay } from "rxjs";
import { ServiceErrorHandler } from "../models/service-error-handler-decorator";
import { AbstractService } from "./common/abstract.service";

@Injectable({
  providedIn: "root",
})
export class CorrectionRequestService extends AbstractService {
  constructor(private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public postAttendanceRequest(body: any): Observable<any> {
    return this.http.post<any>(`/api/attendance/requests`, body).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  public updateAttendanceRequest(id: any, body: any,type): Observable<any> {
    return this.http.put<any>(`/api${type}/attendance/requests/${id}`, body).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  public getAttendanceRequestEmployee(type): Observable<any> {
    return this.http.get<any>(`/api${type}/attendance/requests`, this.httpOptions)
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.data;
          }
        }),
        shareReplay()
      );
  }

  @ServiceErrorHandler()
  public deleteAttendanceRequest(id: any): Observable<any> {
    return this.http.delete<any>(`/api/attendance/requests/${id}`).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }
}
