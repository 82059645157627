import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
@Component({
  selector: "ngx-mat-progress-spinner",
  templateUrl: "./mat-progress-spinner.component.html",
  styleUrls: ["./mat-progress-spinner.component.scss"],
})
export class MatProgressSpinnerComponent implements OnInit {
  @Input() isLoading: boolean = false;
  @Input() counter: number = 8;
  constructor() {}
  ngOnInit() {}
}
