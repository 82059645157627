<div *ngIf="isLoaded">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 w-100">

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
                <ngx-custome-checkbox (change)="onChangeStatus($event,element)" [status]="element.is_active">
                </ngx-custome-checkbox>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element" (click)="searchInput(element.id)"> {{element.name}} </td>
        </ng-container>

        <!-- Desigantion Column -->
        <ng-container matColumnDef="desigantion">
            <th mat-header-cell *matHeaderCellDef> Desigantion </th>
            <td mat-cell *matCellDef="let element" (click)="searchInput(element.id)">
                {{element?.personalProfile?.designation?.name}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element" (click)="searchInput(element.id)"> {{element.email}} </td>
        </ng-container>

        <!-- QR Column -->
        <ng-container matColumnDef="qr">
            <th mat-header-cell *matHeaderCellDef> QR </th>
            <td mat-cell *matCellDef="let element">
                <qrcode title="Download QR" (click)="downloadQr(element.email)" [elementType]="'canvas'" [scale]="1"
                    #parent qrdata="{{element.email}}" [width]="10" [errorCorrectionLevel]="'M'"></qrcode>
            </td>
        </ng-container>

        <!--Delete Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"> <i (click)="deleteEmployee(element.id)"
                    *ngIf="currentUserId!=element.id" class="fa fa-trash" aria-hidden="true"></i> </td>
        </ng-container>
        <!-- no record found -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999">No Record found</td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator #paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]" (page)="getDataOnPageChange($event)">
    </mat-paginator>
</div>

<ngx-mat-progress-bar [title]="'Please Wait...'" [isLoaded]="isLoaded"></ngx-mat-progress-bar>