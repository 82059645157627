<label class="title h4">Attendance Correction</label>
<div class="d-flex justify-content-between">
    <div>
    </div>
    <div *ngIf="isEmployee">
        <button nbButton status="primary" class="employee-btn" (click)="requestCorrection()">
            Request
        </button>
    </div>
</div>
<table mat-table [dataSource]="dataSource" class="mt-3 mat-elevation-z1 w-100">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
            <div *ngIf="!isEmployee">Name</div>
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.status!='pending'?'not-allowed':''"
            (click)="element.status=='pending'?onUpdateEmployeeRequest(element):''">
            <div *ngIf="!isEmployee"> {{ element?.requestedBy?.name }}</div>

        </td>
    </ng-container>
    <!-- Name Column -->

    <!-- Description Column -->
    <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef>Reason</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.status!='pending'?'not-allowed':''"
            (click)="element.status=='pending'?onUpdateEmployeeRequest(element):''">
            {{ element?.reason }}
        </td>
    </ng-container>
    <!-- Description Column -->

    <!-- Check-in column -->
    <ng-container matColumnDef="checkIn">
        <th mat-header-cell *matHeaderCellDef>Check In</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.status!='pending'?'not-allowed':''"
            (click)="element.status=='pending'?onUpdateEmployeeRequest(element):''">
            {{ element?.check_in|date:'shortTime' }}


        </td>
    </ng-container>
    <!-- Check-in column -->

    <!-- Check-out column -->
    <ng-container matColumnDef="checkOut">
        <th mat-header-cell *matHeaderCellDef>Check Out</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.status!='pending'?'not-allowed':''"
            (click)="element.status=='pending'?onUpdateEmployeeRequest(element):''">
            {{ element?.check_out|date:'shortTime' }}
        </td>
    </ng-container>
    <!-- Check-out column -->

    <!-- Date column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.status!='pending'?'not-allowed':''"
            (click)="element.status=='pending'?onUpdateEmployeeRequest(element):''">
            {{ element?.check_in|date:'shortDate' }}
        </td>
    </ng-container>
    <!-- Date column -->

    <!-- Status column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.status!='pending'?'not-allowed':''"
            (click)="element.status=='pending'?onUpdateEmployeeRequest(element):''">
            <span class="danger" *ngIf="element.status==='rejected'">{{ element?.status}}</span>
            <span class="success" *ngIf="element.status==='approved'">{{ element?.status}}</span>
            <span class="primary" *ngIf="element.status==='pending'">{{ element?.status}}</span>
        </td>
    </ng-container>
    <!-- Status column -->

    <!-- Action Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="text-center">
            <i (click)="deleteCorrectionRequest(element.id)" class="fa fa-trash color-red"
                aria-hidden="true"></i>
        </td>
    </ng-container>
    <!-- no record found -->
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">No Record found</td>
    </tr>
    <!-- no record found -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</table>
