import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Designation, DesignationAdapter } from '../models/designation.model';

@Injectable({
  providedIn: 'root'
})
export class DesignationService {

  constructor(private http:HttpClient, private adapter: DesignationAdapter) { }


  public getAllDesignations():Observable<Designation[]> {
    return this.http.get<Designation[]>('/api/admin/designations')
    .pipe(
      map((res:any) => {
          let data = res.data;
          return data.map((item: any) => {
            return this.adapter.adapt(item);
          });
      })
    );
  }

  public getByName(data:any):Observable<any> {
    let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        const params = new HttpParams()
        .set('id', data);
    return this.http.get(`designations/show/${data}`, {headers:headers})
    .pipe(
      map((res:any) => {
        let data:any[] = [];
        if(res.success){
          data = res.data;
          return data;
        }else{
          return data;
        }
        shareReplay();

      })
    );
  }

  public addDesignation(data:any):Observable<any> {
    let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post('/api/admin/designations', data, {headers:headers})
    .pipe(
      map((res:any) => {
        if(res.success){
          let data = res.data;
          return data;
        }else{
        }
        shareReplay()

      })
    );
  }


  public updateDesignation(id:any,data:any):Observable<any> {
    let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put(`/api/admin/designations/${id}`, data, {headers:headers})
    .pipe(
      map((res:any) => {
        if(res.success){
          let data = res.data;
          return data;
        }else{
        }
        shareReplay()

      })
    );
  }

  public deleteDesignation(id:any):Observable<any> {
    let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.delete(`/api/admin/designations/${id}`, {headers:headers})
    .pipe(
      map((res:any) => {
        if(res.success){
          return res.success;
        }else{
        }
        shareReplay();
      })
    );
  }
}
