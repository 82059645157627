<ng-container>
    <h1 id="title" class="title">Login</h1>
    <p class="sub-title">Hello! Log in with your email.</p>
    <form novalidate aria-labelledby="title" class="ng-untouched ng-pristine" (ngSubmit)="login()">
        <div class="form-control-group">
            <label for="input-email" class="label">Email address:</label>
            <input nbInput fullWidth name="email" id="input-email" pattern=".+@.+\..+" placeholder="Email address" fieldsize="large" autofocus=""  class="size-large ng-untouched ng-pristine ng-invalid nb-transition" required [(ngModel)]="entity.email">
        </div><div class="form-control-group">
            <span class="label-with-link">
                <label for="input-password" class="label">Password:</label>
                <a routerlink="../request-password" class="forgot-password caption-2" ng-reflect-router-link="../request-password" href="/auth/request-password" [(ngModel)]="entity.password">Forgot Password?</a>
            </span>
            <input nbInput fullWidth name="password" type="password" id="input-password" placeholder="Password" fieldsize="large"  class="size-large ng-untouched ng-pristine ng-invalid nb-transition" required="" minlength="4" maxlength="50">
        </div>
        <div class="form-control-group accept-group">
            <nb-checkbox name="rememberMe" _nghost-hhg-c148="" ng-reflect-name="rememberMe" class="status-basic ng-untouched ng-pristine ng-valid nb-transition">
                <label _ngcontent-hhg-c148="" class="label">
                    <input _ngcontent-hhg-c148="" type="checkbox" class="native-input visually-hidden">
                    <span _ngcontent-hhg-c148="" class="text">Remember me</span>
                </label>
            </nb-checkbox>
        </div>
        <button nbbutton fullwidth status="primary" size="large" class="appearance-filled full-width size-large shape-rectangle status-primary"> Log In </button>
    </form>
</ng-container>