import { HttpRequest } from "@angular/common/http";
import {ChangeDetectorRef, Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class Globals {
    user: {
        id: number,
        name:number,
        email: string,
        phone_no: any,
        date_joined: Date,
        last_login: Date,
        is_active:boolean
        roles : any[]
      }
    lastRequest: HttpRequest<any>;
}