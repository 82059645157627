import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private TOKEN:any;
  
  URLsToNotUse :any = [
    '/login'
  ];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.TOKEN = localStorage.getItem('token');
    if(this.URLsToNotUse.includes(request.url)){
      return next.handle(request);
    }
    const dupRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.TOKEN}`
      }
    });
    
    return next.handle(dupRequest);
  }
}
