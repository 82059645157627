import * as moment from "moment";
import { environment } from "../../../environments/environment";
import { dateIso } from "./dateiso";

export function daysFormat(date){
    return moment(dateIso(date, false, environment.timezone)).format('YYYY-MM-DD')
}
export function timeFormat(date){
    return moment(dateIso(date, false, environment.timezone)).format('hh:mm a')
}
export function dateFormat(date){
    return moment(dateIso(date, false, environment.timezone)).utc().format()
}