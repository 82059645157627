import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, shareReplay } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PublicHolidaysService {
  constructor(private http: HttpClient) {}

  public getPublicHolidays(): Observable<any[]> {
    return this.http.get<any[]>("/api/admin/public-holidays").pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
        shareReplay();
      })
    );
  }

  public addPublicHoliday(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");
    return this.http
      .post("/api/admin/public-holidays", data, { headers: headers })
      .pipe(
        map((res: any) => {
          if (res.success) {
            let data = res.data;
            return data;
          }
          shareReplay();
        })
      );
  }

  public updatePublicHoliday(id: any, data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");
    return this.http
      .put(`/api/admin/public-holidays/${id}`, data, { headers: headers })
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.data;
          }
          shareReplay();
        })
      );
  }

  public deletePublicHoliday(id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");
    return this.http
      .delete(`/api/admin/public-holidays/${id}`, { headers: headers })
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.success;
          }
          shareReplay();
        })
      );
  }
}
