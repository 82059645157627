<div class="d-flex justify-content-between">
    <div class="left-image-section">
        <img alt="Scanner Image" [src]="data.scanImage" width="200" height="200">
    </div>
    <div class="right-details-section w-100">
        <nb-card class="w-100 border-none">
            <nb-card-header class="h6 border-none">Attendance Details</nb-card-header>
            <nb-card-body>
              <div class="info">
                <div class="h6 value mb-10">{{data.user.name}}</div>
                <div class="details subtitle-2 row">
                  <div class="col-md-6">
                    <label>Check In At:</label>
                  </div>
                  <div class="col-md-6">
                    <label>{{data.check_in | date : 'shortTime' }}</label>
                  </div>
                  <div class="col-md-6" *ngIf="data.check_out">
                    <label>Check Out At:</label>
                  </div>
                  <div class="col-md-6" *ngIf="data.check_out">
                    <label>{{data.check_out | date : 'shortTime' }}</label>
                  </div>
                </div>
              </div>
            </nb-card-body>
          </nb-card>
    </div>
</div>
