import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { tap } from "rxjs";
import { CorrectionRequestService } from "../../../../../@shared/services/correction-request.service";
import { PublicHolidaysService } from "../../../../../@shared/services/public-holidays.service";
import { Globals } from "../../../../../gloabls";

@Component({
  selector: "ngx-request-correction",
  templateUrl: "./request-correction.component.html",
  styleUrls: ["./request-correction.component.scss"],
})
export class RequestCorrectionComponent implements OnInit {
  form: FormGroup;
  roles: any = [];
  isEmployee: boolean = false;
  pipe = new DatePipe("en-US");
  constructor(
    private fb: FormBuilder,
    public correctionRequestService: CorrectionRequestService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globals: Globals
  ) {
    this.form = this.fb.group({
      reason: ["", [Validators.required]],
      checkIn: ["", [Validators.required]],
      checkOut: ["", [Validators.required]],
      date: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.roles = this.globals.user.roles;
    if (this.roles.includes("employee") && this.roles.length === 1) {
      this.isEmployee = true;
    }
    if (this.data.status === "update") {
      const formattedDate: any = this.pipe.transform(
        this.data.data.check_in,
        "shortDate"
      );
      this.form = this.fb.group({
        reason: this.data.data.reason,
        checkIn: new Date(this.data.data.check_in),
        checkOut: new Date(this.data.data.check_out),
        date: new Date(formattedDate),
      });
    }
  }

  updateCorrectionReq(type=''): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      const data = this.getFormatedDates();
      const formData = new FormData();
      //append data into formdata
      formData.append(
        "check_in",
        data.formattedDate + " " + data.formattedCheckIn
      );
      formData.append(
        "check_out",
        data.formattedDate + " " + data.formattedCheckOut
      );
      formData.append("reason", this.form.value.reason);
      if(type){
        formData.append('status',type);
      }
      this.correctionRequestService
        .updateAttendanceRequest(this.data.data.id, formData,this.data.type)
        .pipe(
          tap((res: any) => {
            console.log("updated", res);
            this.dialogRef.close(true);
          })
        )
        .subscribe();
    }
  }

  getFormatedDates(): any {
    const formattedDate: any = this.pipe.transform(
      this.form.value.date,
      "shortDate"
    );
    const formattedCheckIn: any = this.pipe.transform(
      this.form.value.checkIn,
      "h:mm:ss"
    );

    const formattedCheckOut: any = this.pipe.transform(
      this.form.value.checkOut,
      "h:mm:ss"
    );

    return {
      formattedDate,
      formattedCheckIn,
      formattedCheckOut,
    };
  }

  createCorrectionReq(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      const data = this.getFormatedDates();
      const formData = new FormData();
      //append data into formdata
      formData.append(
        "check_in",
        data.formattedDate + " " + data.formattedCheckIn
      );
      formData.append(
        "check_out",
        data.formattedDate + " " + data.formattedCheckOut
      );
      formData.append("reason", this.form.value.reason);

      this.correctionRequestService
        .postAttendanceRequest(formData)
        .pipe(
          tap((res: any) => {
            this.dialogRef.close(true);
          })
        )
        .subscribe();
    }
  }
  public onStatusChange(type): void{
    this.updateCorrectionReq(type);
  }
}
