import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {leaveManager} from '../models/leaveManagerModel'
import { ServiceErrorHandler } from '../models/service-error-handler-decorator';
import { AbstractService } from "./common/abstract.service";

@Injectable({
  providedIn: 'root'
})
export class LeaveListService extends AbstractService {

  constructor(private http:HttpClient,) {
    super();
  }


  @ServiceErrorHandler()
  public getAll(dateStart: any, dateEnd: any): Observable<any> {
    return this.http
      .get<any>(
        `/api/leaves?from=${dateStart}&to=${dateEnd}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          }
        }),
        shareReplay()
      );
  }

  public getAllUsersLeave(dateStart: any, dateEnd: any): Observable<any> {
    return this.http
      .get<any>(
        `/api/admin/leaves/users/all?from=${dateStart}&to=${dateEnd}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          }
        }),
        shareReplay()
      );
  }
}
