import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendanceComponent } from './attendance.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { NgxCaptureModule } from 'ngx-capture';
import { NbActionsModule, NbAlertModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbIconModule, NbInputModule, NbMenuModule } from '@nebular/theme';
import { MarkSuccessComponent } from './components/mark-success/mark-success.component';
import { AttendanceRoutingModule } from './attendance-routing.module';
import { ThemeModule } from '../../../@theme/theme.module';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { MatProgressSpinnerComponent } from "../mat-progress-spinner/mat-progress-spinner.component";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
@NgModule({
  declarations: [
    AttendanceComponent,
    QrScannerComponent,
    MarkSuccessComponent,
    MatProgressSpinnerComponent
  ],
  imports: [
    CommonModule,
    AttendanceRoutingModule,
    NgxScannerQrcodeModule,
    NgxCaptureModule,
    NbMenuModule,
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbCheckboxModule,
    NbIconModule,
    NbAlertModule,
    MatProgressSpinnerModule
  ],
  exports:[
    AttendanceComponent,
    QrScannerComponent,
    MarkSuccessComponent,
    MatProgressSpinnerComponent,
  ]
})
export class AttendanceModule { }
