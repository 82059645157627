import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { delay, finalize, first, map, Observable, tap } from 'rxjs';
import { AbstractService } from '../../@shared/services/common/abstract.service';
import { Globals } from '../../gloabls';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractService{

  constructor(private globals: Globals,private router: Router, private http:HttpClient) {
    super();
   }

  public userlogin(data): Observable<any> {
    return this.http.post(`/api/login`, data, this.httpOptions)
    .pipe(
      delay(500),
      map((res:any)=>res.data),
      tap((configs:any)=>{
        if(configs){
          localStorage.setItem("token", configs.token);
          this.globals.user=configs.user;
          this.router.navigateByUrl('/pages/attendance/dashboard');
        }
      }),
    );
  }
}
