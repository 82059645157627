import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { ThemeModule } from '../@theme/theme.module';
import { AuthComponent } from './auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbAuthModule } from '@nebular/auth';
import {
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule
} from '@nebular/theme';
import { AttendanceLoginComponent } from './attendance-login/attendance-login.component';
import { RecruitmentLoginComponent } from './recruitment-login/recruitment-login.component';
import { ResetPasswordComponent } from './attendance-login/reset-password/reset-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AuthComponent,
    AttendanceLoginComponent,
    RecruitmentLoginComponent,
    ResetPasswordComponent,
    NewPasswordComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,

    AuthRoutingModule,
    ThemeModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbAuthModule,
  ],
})
export class AuthModule { }
