import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NbAuthComponent } from '@nebular/auth';
import { AttendanceComponent } from './attendance.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';

const routes: Routes = [{
  path: '',
  component: NbAuthComponent,
  children: [
    {
      path: '',
      component: AttendanceComponent,
    },
  ],
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttendanceRoutingModule { }
