<div class="text-center modal open" id="forgotPasswordModal">
  <div class="modal-dialog modal-dialog-centered modal-lg">

      <div class="modal-content">

          <div class="modal-body">

              <span mat-dialog-close (click)="closeModel()" style="display: block; text-align: right">
                  <img class="cross-icon" src="../../assets/images/cancel.png">
              </span>

              <h3 class="mb-0">Oops! Forgot Your Password?</h3>
              <p class="pb-1 text-muted">We have your back. We'll reset it for you.</p>

              <form (keydown.enter)="onResetFormSubmit()">
                  <div class="mx-auto mb-3 input-group">
                      <input aria-label="user email" aria-required="true" class="form-control" id="forgot-email" name="forgot-email" placeholder="myemail@email.com" required
                             type="email">
                      <div class="input-group-append">
                          <button (click)="onResetFormSubmit()" class="btn btn-primary" id="button-addon2" type="submit">
                              <img alt="send-button" src="../../assets/images/paper-plane.svg" style="width: 18px; margin-top: 3px">
                          </button>
                      </div>
                  </div>
                  <div class="row justify-content-center">
                      <div class="col-11 col-md-6">
                          <div *ngIf="forgotPasswordSuccessMessage" class="alert alert-success">
                              {{forgotPasswordSuccessMessageText}}
                          </div>
                          <div *ngIf="forgotPasswordErrorMessage" class="alert alert-danger">
                              {{forgotPasswordErrorMessageText}}
                          </div>
                      </div>
                  </div>
              </form>

          </div>
      </div>
  </div>

</div>
