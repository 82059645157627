import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, shareReplay } from "rxjs";
import { CriteriaAdapter } from "../models/criteria.model";
import { ServiceErrorHandler } from "../models/service-error-handler-decorator";
import { AbstractService } from "./common/abstract.service";

@Injectable({
  providedIn: "root",
})
export class SpentHoursService extends AbstractService {
  constructor(private http: HttpClient, private adapter: CriteriaAdapter) {
    super();
  }

  // modify with respect to response
  @ServiceErrorHandler()
  public getAverageAttendance(date): Observable<any> {
    return this.http
      .get<any>(
        `/api/admin/attendance/users/average?from=${date.start.toLocaleDateString(
          "en-CA"
        )}&to=${date.end.toLocaleDateString("en-CA")}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          }
        }),
        shareReplay()
      );
  }

  @ServiceErrorHandler()
  public getAverageUserAttendance(date,user_id): Observable<any> {
    return this.http
      .get<any>(
        `/api/attendance/users/average?from=${date.start.toLocaleDateString(
          "en-CA"
        )}&to=${date.end.toLocaleDateString("en-CA")}&user_id=${user_id}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          }
        }),
        shareReplay()
      );
  }
}
