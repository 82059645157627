import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Globals} from '../../gloabls';
import {AppInjector} from '../../app-injector';
import { showFeedback } from './feedbacks';
import { ToastService } from '../../@core/services/toast.service';
declare var tost:ToastService
export function ServiceErrorHandler(): any {
  return function (target: any, name: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;
    descriptor.value = function (...args) {
      const call: Observable<any> = method.apply(this, args);
      return call.pipe(
        catchError((e: HttpErrorResponse) => {
          const globals: Globals = AppInjector ? AppInjector.get(Globals) : null;

          const errorObject = {
            error: e,
            request: globals && globals.lastRequest ? globals.lastRequest : null,
          };

          switch (e.status) {
            case 500:
              // showFeedback('danger', 'Error',e.message)
              break;
            case 401:
              // window.location.reload();
              break;
            case 403:
              // window.location.href='/auth/attendance/login'
              break;
            case 504:
            //   showFeedback('Oeps. Dat kon onze server niet aan. Probeer a.u.b. een kleinere bewerking', 'error', errorObject, true);
              break;
            default:
            //   showFeedbackError(errorObject);
              break;
          }
          return throwError(()=>e);
        })
      );
    };
  };
}
