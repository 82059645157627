import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  private APP_URL:string = environment.apiUrl;
  private DEV_APP_URL:string = environment.dev_apiUrl;


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const newUrl = this.getApiUrl() + request.url;
    const dupRequest = request.clone({
      url: newUrl
    });

    return next.handle(dupRequest);
  }

  getApiUrl(): string {
    const currentUrl = window.location.href;
    if (currentUrl.startsWith(this.APP_URL)) {
      return this.APP_URL;
    } else if (currentUrl.startsWith(this.DEV_APP_URL)) {
      return this.DEV_APP_URL;
    } else {
      return this.APP_URL;
    }
  }

}
