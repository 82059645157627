import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Globals } from '../../gloabls';
import { ProjectService } from '../services/project.service';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  constructor() { }
  public load(): Promise<any> {
    return;
  }
}
