import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { convertBase64ToBlob } from '../../../../@shared/models/attendanceModal';
import { dateIso } from '../../../../@shared/models/dateiso';
import { userBarData } from '../../../../@shared/models/highcharts';
import { daysFormat } from '../../../../@shared/models/moment';
import { AttendanceService } from '../../../../@shared/services/attendance.service';
import { Globals } from '../../../../gloabls';
import { User } from '../../../../@shared/models/statistics/statistics.model';
import { StaticticsService } from '../../../../@shared/services/statistics.service';

@Component({
  selector: 'ngx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ["name", "checkin", "checkout", "leave", "spenthours"];
  dataSource: any[] = [];
  dataSource2: any[] = [];
  currentDate:any = new Date(moment().format('YYYY-MM-DD'));
  public users$: Observable<User>;
  public selectedUser: any ;
  public isLoad: boolean = true;
  isEmployee: boolean = false;
  public isInActive: boolean = true;

  isLoaded:boolean;

  public userAttendance$: Observable<any>;
  public statisticsChart: any;
  public today=daysFormat(new Date());
  roles : any[] = [];

  constructor(
    public _attendanceServices: AttendanceService,
    private globals: Globals,
    private cd: ChangeDetectorRef,
    private _staticticsService: StaticticsService) {
    }

  ngOnInit(): void {
    this.roles = this.globals.user.roles;
    if (this.roles.includes("employee") && this.roles.length === 1) {
      this.isEmployee = true;
      this.selectedUser = this.globals.user;
    } else {
      this.loadUsers();
    }
    this.cd.detectChanges();
  }

    //load all
    public loadUsers() {
      this.users$ = this._staticticsService.getUsers();
    }

  ngAfterViewInit(): void {


    if(this.roles.includes("employee") && this.roles.length === 1){
    this.userAttendance$=this._attendanceServices.getUserAttendanceDashboard(this.today,this.today);

    }else{
    this.userAttendance$=this._attendanceServices.getAttendance(this.today,this.today,this.globals.user.id);
    this.getallUsersStats(this.currentDate);
    };
  }

  public saveAsImage(parent: any){
    let parentElement = null
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")
    if (parentElement) {
      let blobData = convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = "Qrcode"
      link.click()
    }
  }

  // get all employee stats by selecting date
  public getallUsersStats(date){
    date = moment(date).format('YYYY-MM-DD');
    this.isLoaded = false;
    this._attendanceServices
      .allEmployeeStats(date)
      .pipe(
        tap((res: any) => {
          this.dataSource2 = res;
          this.isLoaded = true;
        })
      )
      .subscribe();
  }

  // employee stats table's col styling
  public getCellStyle(workinHours){
    if(workinHours){
      workinHours = +workinHours.slice(0,2)
      if (workinHours >= 9 ) {
        return { 'color': 'green' };
      } else {
        return { 'color': 'red' };
      }
    }
  }
  // public onUserSelection($event){
  //   { this.isLoaded = false;
  //     this._attendanceServices
  //     .allusersleaveqouta($event.id)
  //     .pipe(
  //       tap((res: any) => {
  //         this.dataSource=res;
  //         this.isLoaded = true;
  //       })
  //     )
  //     .subscribe();

  //   }
  // }



  //date formater
  public dateFormater(date){
    if(!date){
      return false
    }
    return moment(dateIso(date, false, environment.timezone)).format('hh:mm a')
  }

}
