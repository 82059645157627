import {HttpHeaders} from "@angular/common/http";

export class AbstractService {

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
   });

  httpOptions = {
    headers: this.headers
  };
}
