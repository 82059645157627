import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ngx-mat-progress-bar",
  templateUrl: "./mat-progress-bar.component.html",
  styleUrls: ["./mat-progress-bar.component.scss"],
})
export class MatProgressBarComponent implements OnInit {
  @Input() title: any;
  @Input() isLoaded: any;
  constructor() {}

  ngOnInit(): void {}
}
