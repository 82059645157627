<div *ngIf="isLoaded">
  <div class="row">
    <div class="col-md-6">
      <h6 mat-dialog-title>Add Employee</h6>
    </div>
    <div class="col-md-6">
      <nb-icon mat-dialog-close class="float-right close-icon" icon="close"></nb-icon>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="submitForm()" class="form">
    <mat-dialog-content class="mat-typography">
      <!-- Name  -->
      <div class="form-group">
        <label for="exampleInputName" class="label">Full Name<span
            *ngIf="form.get('name')?.errors?.required">*</span></label>
        <input type="text" nbInput fullWidth id="exampleInputName" placeholder="Enter name" formControlName="name" />
        <small class="text-danger" *ngIf="
            form.get('name').hasError('required') && form.get('name').touched
          ">
          This field is required.
        </small>
        <small class="text-danger" *ngIf="
            form.get('name').hasError('minlength') && form.get('name').touched
          ">
          The minimum length for this field is
          {{ form.get("name").errors.minlength.requiredLength }}
          characters.
        </small>
      </div>
      <!-- Name  -->

      <!-- Email  -->
      <div class="form-group">
        <label for="exampleInputEmail" class="label">Email<span
            *ngIf="form.get('email')?.errors?.required">*</span></label>
        <input type="email" nbInput fullWidth id="exampleInputEmail" placeholder="Enter email"
          formControlName="email" />
        <small class="text-danger" *ngIf="
            form.get('email').hasError('required') && form.get('email').touched
          ">
          This field is required.
        </small>
        <small class="text-danger" *ngIf="
            form.get('email').hasError('minlength') && form.get('email').touched
          ">
          The minimum length for this field is
          {{ form.get("email").errors.minlength.requiredLength }}
          characters.
        </small>
        <small class="text-danger" *ngIf="
            (form.get('email').hasError('email') ||
              form.get('email').hasError('pattern')) &&
            form.get('email').touched
          ">
          Please provide a valid email address.
        </small>
      </div>
      <!-- Email  -->

      <!-- Password, Confrim_password  -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="exampleInputPassword" class="label">Password<span
                *ngIf="form.get('password')?.errors?.required">*</span></label>
            <input type="password" nbInput fullWidth id="exampleInputPassword" placeholder="Enter password"
              formControlName="password" />
            <small class="text-danger" *ngIf="
                form.get('password').hasError('required') &&
                form.get('password').touched
              ">
              This field is required.
            </small>
            <small class="text-danger" *ngIf="
                form.get('password').hasError('minlength') &&
                form.get('password').touched
              ">
              The minimum length for this field is
              {{ form.get("password").errors.minlength.requiredLength }}
              characters.
            </small>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="exampleInputConfirmPassword" class="label">Confirm Password<span
                *ngIf="form.get('confirm_password')?.errors?.required">*</span></label>
            <input type="password" nbInput fullWidth id="exampleInputConfirmPassword" placeholder="Enter password"
              formControlName="confirm_password" />
            <small class="text-danger" *ngIf="
                form.get('confirm_password').hasError('required') &&
                form.get('confirm_password').touched
              ">
              This field is required.
            </small>
            <small class="text-danger" *ngIf="
                !form.get('confirm_password').hasError('required') &&
                form.get('confirm_password').hasError('mismatch') &&
                form.get('confirm_password').touched
              ">
              Password doesn't match.
            </small>
          </div>
        </div>
      </div>
      <!-- Password, Confrim_password  -->

      <!-- Phone no# -->
      <div class="form-group">
        <label for="exampleInputNumber" class="label">Phone no#</label>
        <input type="text" (keyup)="validateNumber()" nbInput fullWidth id="exampleInputNumber"
          placeholder="Enter phone" formControlName="phone" />
      </div>
      <!-- Phone no# -->

      <!-- Status/active -->
      <div class="form-group">
        <nb-checkbox status="success" formControlName="is_active">Status/Active</nb-checkbox>
      </div>
      <!-- Status/active -->

      <!-- youtack integration -->
      <div class="form-group">
        <nb-checkbox status="success" formControlName="youtrack_integration">YouTrack</nb-checkbox>
      </div>
       <!-- youtack integration -->

      <!-- Employment status     *ngIf="form.get('employment_status.id')?.errors?.required" formGroupName="employment_status"-->
      <div class="form-group">
        <label for="exampleInputNumber" class="label">Employment Status<span>*</span></label>
        <nb-select fullWidth formControlName="employment_status" [compareWith]="objectComparisonFunction">
          <nb-option value=""> Choose an option </nb-option>
          <nb-option [value]="status.name" *ngFor="let status of employmentStatus"
            (click)="getEmployeeStatus(status.name)">
            <ng-container *ngIf="status.is_active">
              {{ status.name | capitalizeFirstLetter }}</ng-container>
          </nb-option>
        </nb-select>
        <!-- <small class="text-danger"
                    *ngIf="form.get('employment_status.id').hasError('required')&&form.get('employment_status.id').touched">
                    This field is required.
                </small> -->
      </div>
      <!-- Employment status -->


      <!-- Select Roles -->

      <div class="form-group">
        <label for="exampleInputNumber" class="label">Employment Roles<span>*</span></label>
        <nb-select fullWidth formControlName="roles" multiple>
          <nb-option [value]="roles.name" *ngFor="let roles of employmentRoles" (click)="selectRoles(roles.name)">
            <ng-container>
              {{ roles.name | capitalizeFirstLetter }}</ng-container>
          </nb-option>
        </nb-select>
      </div>

      <!-- Select Roles -->

      <!-- Date of join,confirmation date-->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="joiningdata" class="label">Joining Date</label>
            <input id="joiningdata" fullWidth formControlName="date_joined" nbInput placeholder="Select date"
              [nbDatepicker]="formpicker" />
            <nb-datepicker #formpicker></nb-datepicker>
          </div>
        </div>
        <!-- *ngIf="showConfrimData" -->
        <div class="col-md-6">
          <div class="form-group">
            <label for="dateconfirmed" class="label">Confirmation Date</label>
            <input id="dateconfirmed" fullWidth formControlName="date_of_permanent" nbInput placeholder="Select date"
              [nbDatepicker]="formpicker1" />
            <nb-datepicker #formpicker1></nb-datepicker>
          </div>
        </div>
      </div>
      <!-- Date of join,confirmation date-->

      <!-- Designation,  Department -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group" formGroupName="designation">
            <label for="exampleInputNumber" class="label">Designation<span
                *ngIf="form.get('designation.id')?.errors?.required">*</span></label>
            <nb-select fullWidth formControlName="id" [compareWith]="objectComparisonFunction">
              <nb-option value=""> Choose an option </nb-option>
              <nb-option [value]="designation.id" *ngFor="let designation of designations">
                <ng-container *ngIf="designation.is_active">
                  {{ designation.name }}</ng-container>
              </nb-option>
            </nb-select>
            <small class="text-danger" *ngIf="
                form.get('designation.id').hasError('required') &&
                form.get('designation.id').touched
              ">
              This field is required.
            </small>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group" formGroupName="department">
            <label for="exampleInputNumber" class="label">Department<span
                *ngIf="form.get('department.id')?.errors?.required">*</span></label>
            <nb-select fullWidth formControlName="id" [compareWith]="objectComparisonFunction">
              <nb-option value=""> Choose an option </nb-option>
              <nb-option [value]="department.id" *ngFor="let department of departments">
                <ng-container *ngIf="department.is_active">
                  {{ department.name }}</ng-container>
              </nb-option>
            </nb-select>
            <small class="text-danger" *ngIf="
                form.get('department.id').hasError('required') &&
                form.get('department.id').touched
              ">
              This field is required.
            </small>
          </div>
        </div>
      </div>
      <!-- Designation,  Department -->
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <!-- buttons -->
      <button size="small" mat-dialog-close nbButton>Cancel</button>
      <button size="small" [disabled]="!form.valid" nbButton status="primary" class="employee-btn" type="submit">
        Save
      </button>
    </mat-dialog-actions>
    <!-- buttons -->
  </form>
</div>

<ngx-mat-progress-bar [title]="'Please Wait...'" [isLoaded]="isLoaded"></ngx-mat-progress-bar>