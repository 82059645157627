import { Injectable } from "@angular/core";
import { Adapter } from "../adapter";

export class Criteria {
    constructor(
    public id : number,
    public name : string
    ){}
}

@Injectable({
    providedIn: "root",
  })
  export class CriteriaAdapter implements Adapter<Criteria> {
    adapt(item: any): Criteria {
      return new Criteria(
          item.id,
          item.name
          );
    }
  }
