import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, of, tap } from 'rxjs';
import { AuthService } from '../../@core/services/auth.service';
import { ToastService } from '../../@core/services/toast.service';
import { modalConfig, ModalEnum } from '../../@shared/models/modalConfigs';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@Component({
  selector: 'ngx-attendance-login',
  templateUrl: './attendance-login.component.html',
  styleUrls: ['./attendance-login.component.scss']
})
export class AttendanceLoginComponent implements OnInit, AfterViewInit {
  public entity:any;

  constructor(private _authServices: AuthService, public dialog: MatDialog, private _toastService: ToastService) { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.entity={
      email: null,
      password: null,
    }
  }

  public login(): void{
    this._authServices.userlogin(this.entity).pipe(
      tap((res: any) => {
       
      }),
      catchError((err: any) => {
        this._toastService.showToast("danger", "Error", "User cannot be logged in");
        return of(err);
      })
    ).subscribe(res=>{
    });
  }

  resetPassword() {
    const modal = this.dialog.open(
      ResetPasswordComponent,
      modalConfig(
        {
          data: "",
          panelClass: ["animate__animated", "animate__slideInRight"],
        },
        ModalEnum.ModalSmall
      )
    );
    modal.afterClosed().subscribe((res) => {
      //refresh tab data based on current active tab

    });
  }
}
