import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import html2canvas from "html2canvas";
import { NgxCaptureService } from "ngx-capture";
import { NgxScannerQrcodeComponent } from "ngx-scanner-qrcode";
import { catchError, Observable, of, Subscription, tap } from "rxjs";
import { ToastService } from "../../../../../@core/services/toast.service";
import {
  convertToFormData,
  dataURLtoFile,
} from "../../../../models/attendanceModal";
import { AttendanceService } from "../../../../services/attendance.service";

@Component({
  selector: "ngx-qr-scanner",
  templateUrl: "./qr-scanner.component.html",
  styleUrls: ["./qr-scanner.component.scss"],
})
export class QrScannerComponent implements OnInit, AfterViewInit {
  public onlyOnce: boolean = false;
  public captureFile: any;
  public formData: any;
  public userImage: any;
  public userEmail: any;
  public today = new Date();
  public latitude: any = null;
  public longitude : any = null;
  public error: [] = [];
  private eventsSubscription: Subscription;
  @ViewChild("scanner") scanner: NgxScannerQrcodeComponent;
  @ViewChild("capture") capture: any;

  @Output() _attendanceMarked: EventEmitter<any> = new EventEmitter<any>();
  @Output() _capturingPicture: EventEmitter<any> = new EventEmitter<any>();
  @Input() intervalPassed : Observable<void>;
  constructor(
    private _attendanceService: AttendanceService,
    private captureService: NgxCaptureService,
    private _toastService: ToastService
  ) {}

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    });
  }



  ngAfterViewInit(): void {
    this.scanner.start();
  }
  //on Success scanner
  public onSuccess(data): void {

    if (!this.onlyOnce) {
      this.onlyOnce = true;
      this.userEmail = data;
      this._attendanceService
        .userVerification(data)
        .pipe(
          tap((res: any) => {
            if (res.success){
              this._capturingPicture.emit(true);
              this.captureImage();
            }
          }),
          catchError((err) => {
            this.onlyOnce = false;
            return of(err);
          })
        )
        .subscribe();
    }
  }
  // capture image function
  public captureImage() {
    if(this.latitude != undefined || this.latitude != null || this.longitude != undefined ||this.longitude != null  ){
      this.eventsSubscription =  this.intervalPassed.subscribe(()=>{
        html2canvas(this.capture.nativeElement, { scale: 0.5 }).then((canvas) => {
          this.userImage = canvas.toDataURL();
          this.captureFile = dataURLtoFile(this.userImage, "capture.png");
          this.formData = convertToFormData(this.today, this.captureFile,this.latitude, this.longitude);
          this.markAttendance(this.formData);
        });
        this.eventsSubscription.unsubscribe();
      })
    }
    else{
      this._toastService.showToast("danger", "Error", "Please Enable the location");
    }

  }

  //  mark attendance function
  public markAttendance(data) {
    this._attendanceService
      .attendanceMark(this.userEmail, data)
      .pipe(
        tap((res: any) => {
          if (res.success) {
            res.data["scanImage"] = this.userImage;
            this._attendanceMarked.emit(res.data);
          }else{
            this._toastService.showToast("danger", "Error", res.data ? res.data.scalar ? res.data.scalar  : "Attendance cannot be marked" : "Attendance cannot be marked");
            this.onlyOnce = false;
          }
        }),
        catchError((err: any) => {
          this._toastService.showToast("danger", "Error", "Attendance cannot be marked");
          this.onlyOnce = false;
          return of(err);
        })
      )
      .subscribe();
  }
  ngOnDestroy() {
    this.scanner.stop();
    // this.eventsSubscription.unsubscribe();
  }
}
