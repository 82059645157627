<label class="title h4">Employees</label>
<!-- add and search button for both tabs -->
<div class="d-flex justify-content-between">
  <div>
    <input class="search" type="text" nbInput fullWidth placeholder="Employee" (input)="onSearch($event)">
  </div>
  <div> <button nbButton status="primary" class="employee-btn" (click)="addEmployee()">
      Add Employee
    </button></div>
</div>
<div class="row">
  <div class="col-md-12">
    <nb-tabset #tabGroup (changeTab)="tabChange($event)">
      <nb-tab tabTitle="Active Employee">
        <ngx-employees-active [keyWord]="searchKeyword"></ngx-employees-active>
      </nb-tab>
      <nb-tab tabTitle="Inactive Emloyee">
        <ngx-employee-inactive [keyWord]="searchKeyword"></ngx-employee-inactive>
      </nb-tab>
    </nb-tabset>
  </div>
</div>