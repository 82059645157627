import { AfterViewInit, Component, OnInit } from "@angular/core";
import { tap } from "rxjs";
import { SpentHoursService } from "../../../../@shared/services/spent-hours.service";
import { Globals } from "../../../../gloabls";
@Component({
  selector: "ngx-spent-hours",
  templateUrl: "./spent-hours.component.html",
  styleUrls: ["./spent-hours.component.scss"],
})
export class SpentHoursComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "Employee",
    "Spent Hours",
    "Average Check In",
    "Average Check Out",
    "Days",
    "Average One Day",
  ];
  selectedDate: any = {
    start: "",
    end: "",
  };
  roles: any[] = [];
  dataSource: any = [];
  isLoaded: boolean = false;
  constructor(private spentHoursService: SpentHoursService, private global: Globals) {
    this.roles = this.global.user.roles;
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    var date = new Date();
    this.selectedDate.start = new Date(date.getFullYear(), date.getMonth(), 1);
    this.selectedDate.end = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    );
    if (this.roles.includes("employee") && this.roles.length === 1) {
      this.getAverageUserAttendance(this.selectedDate, this.global.user.id);
    } else {
      this.getAvgSpentHours(this.selectedDate);
    }
  }

  getAverageUserAttendance(selectedDate, userId) {
    this.isLoaded = false;
    this.spentHoursService
      .getAverageUserAttendance(selectedDate, userId)
      .pipe(
        tap((res: any) => {
          this.isLoaded = true;
          this.dataSource = res;
        })
      )
      .subscribe();
  }

  getAvgSpentHours(selectedDate): void {
    this.isLoaded = false;
    this.spentHoursService
      .getAverageAttendance(selectedDate)
      .pipe(
        tap((res: any) => {
          this.isLoaded = true;
          this.dataSource = res;
        })
      )
      .subscribe();
  }

  onSearch(event: any): void { }

  // calendar date selection only deal with range
  public onDateSelection(event: any): void {
    const eventDate = {
      start: event?.start,
      end: event?.end,
    };

    const isEmployee = this.roles.includes("employee") && this.roles.length === 1;

    if (isEmployee && eventDate.start && eventDate.end) {
      this.getAverageUserAttendance(event, this.global.user.id);
    } else if (eventDate.start && eventDate.end) {
      this.getAvgSpentHours(event);
    }
  }

  showTimeSymbols(time: any): string {
    var s = time;
    let parts = s.match(/(\d+)\:(\d+):(\d+)/);
    return (
      parts[1] +
      "h " +
      (parts[2] != "0" ? parts[2] + "m " : "") +
      (parts[3] != "0" ? parts[3] + "s" : "")
    );
  }
}
