import { ResetPasswordService } from './../../../@shared/services/reset-password.service';
import { reset } from './../../../@shared/models/resetPassword';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  loginFormSubmitted = false;
  forgotPasswordFormSubmitted = false;

  forgotPasswordSuccessMessage = false;
  forgotPasswordSuccessMessageText = '';
  forgotPasswordErrorMessage = false;
  forgotPasswordErrorMessageText = '';
  constructor(public resetPasswordService: ResetPasswordService,
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit(): void {
  }
  onResetFormSubmit() {
    this.forgotPasswordSuccessMessage = false;
    this.forgotPasswordErrorMessage = false;
    this.forgotPasswordErrorMessageText = '';
    this.forgotPasswordSuccessMessageText = '';

    if (this.forgotPasswordFormSubmitted) {
      return false;
    } else {
      this.forgotPasswordFormSubmitted = true;
      const regExp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
      const fEmail = (document.getElementById('forgot-email') as HTMLInputElement).value;
      if (fEmail === '') {
        this.forgotPasswordSuccessMessage = false;
        this.forgotPasswordErrorMessage = true;
        this.forgotPasswordErrorMessageText = `Email is Required`;
        this.forgotPasswordFormSubmitted = false;
      } else if (!regExp.test(fEmail)) {
        console.log(regExp.test(fEmail));
        this.forgotPasswordSuccessMessage = false;
        this.forgotPasswordErrorMessage = true;
        this.forgotPasswordErrorMessageText = `Enter a valid Email`;
        this.forgotPasswordFormSubmitted = false;
      } else {
        console.log(fEmail);
        const body = {
          email: fEmail
        }
        this.resetPasswordService.sendEmail(body).subscribe(obj => {
            this.forgotPasswordSuccessMessage = true;
            // @ts-ignore
            if (obj) {
                this.forgotPasswordSuccessMessage = true;
                this.forgotPasswordSuccessMessageText = `A reset link has been sent to the email address provided.`;
                (document.getElementById('forgot-email') as HTMLInputElement).value = '';
                this.dialogRef.close()
            } else {
                this.forgotPasswordErrorMessage = true;
                this.forgotPasswordErrorMessageText = `Something unexpected happened`;
            }
        }, error1 => {
            this.forgotPasswordErrorMessage = true;
            // tslint:disable-next-line:max-line-length
            if (error1.error.errors[0].email[0] === 'There is no active user associated with this e-mail address or the password can not be changed') {
                this.forgotPasswordErrorMessageText = `We can't find anyone with ${fEmail}.`;
                this.forgotPasswordFormSubmitted = false;
            } else {
                this.forgotPasswordErrorMessageText = `Something unexpected happened.`;
                this.forgotPasswordFormSubmitted = false;
            }
            this.forgotPasswordFormSubmitted = false;
        }, () => {
            this.forgotPasswordFormSubmitted = false;
        });
      }
    }
  }

  closeModel() {
    this.dialogRef.close()
  }
}
