import { NgModule } from "@angular/core";
import {
  NbActionsModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbTabsetModule,
  NbToggleModule,
  NbUserModule,
  NbMenuModule,
  NbAlertModule,
} from "@nebular/theme";
import { ThemeModule } from "../../@theme/theme.module";
import { CommonModule } from "@angular/common";
import { AttendanceRoutingModule } from "./attendance-routing.module";
import { AttendanceComponent } from "./attendance.component";
import { AttendanceModule as attendanceFrame } from "../../@shared/components/attendance/attendance.module";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import { QRCodeModule } from "angularx-qrcode";
import { ChartsComponent } from "../../@shared/components/charts/charts.component";
import { HighchartsChartModule } from "highcharts-angular";
import { EmployeesComponent } from "./components/employees/employees.component";
import { AttendanceScannerComponent } from "./components/attendance-scanner/attendance-scanner.component";
import { SpentHoursComponent } from "./components/spent-hours/spent-hours.component";
import { LeavesListComponent } from "./components/leaves-list/leaves-list.component";
import { CustomeCheckboxComponent } from "../../@shared/components/custome-checkbox/custome-checkbox.component";
import { EmployeesDetailComponent } from "./components/employees/employees-detail/employees-detail.component";
import { EmployeesNewComponent } from "./components/employees/employees-new/employees-new.component";
import { MATERIALS } from "../../@shared/app.material";
import interactionPlugin from "@fullcalendar/interaction";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { EmployeesActiveComponent } from "./components/employees/employees-active/employees-active.component";
import { EmployeeInactiveComponent } from "./components/employees/employee-inactive/employee-inactive.component";
import { MatProgressBarComponent } from "../../@shared/components/mat-progress-bar/mat-progress-bar.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FormatTimePipe } from "../../@shared/pipes/format-time.pipe";
import { CapitalizeFirstLetterPipe } from "../../@shared/pipes/capitalize-first-letter.pipe";
import { DesignationsComponent } from "./components/designations/designations.component";
import { AddDesignationComponent } from "./components/designations/add-designation/add-designation.component";
import { LeaveTypesComponent } from "./components/leave-types/leave-types.component";
import { AddLeaveTypesComponent } from "./components/leave-types/add-leave-types/add-leave-types.component";
// Attendacne Module Import
import { NgxScannerQrcodeModule } from "ngx-scanner-qrcode";
import { NgxCaptureModule } from "ngx-capture";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { PublicHolidaysComponent } from "./components/public-holidays/public-holidays.component";
import { AddHolidayComponent } from "./components/public-holidays/add-holiday/add-holiday.component";
import { AttendanceCorrectionComponent } from "./components/attendance-correction/attendance-correction.component";
import { RequestCorrectionComponent } from "./components/attendance-correction/request-correction/request-correction.component";
import { SHARD3RDIMPORTS } from "../../shared-3rd-party";
import { QrDownloadComponent } from './components/employees/qr-download/qr-download.component';

FullCalendarModule.registerPlugins([
  //here register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    AttendanceComponent,
    DashboardComponent,
    StatisticsComponent,
    ChartsComponent,
    EmployeesComponent,
    AttendanceScannerComponent,
    SpentHoursComponent,
    LeavesListComponent,
    CustomeCheckboxComponent,
    EmployeesDetailComponent,
    EmployeesNewComponent,
    EmployeesActiveComponent,
    EmployeeInactiveComponent,
    MatProgressBarComponent,
    FormatTimePipe,
    CapitalizeFirstLetterPipe,
    DesignationsComponent,
    AddDesignationComponent,
    LeaveTypesComponent,
    AddLeaveTypesComponent,
    PublicHolidaysComponent,
    AddHolidayComponent,
    AttendanceCorrectionComponent,
    RequestCorrectionComponent,
    QrDownloadComponent,
  ],
  imports: [
    CommonModule,
    AttendanceRoutingModule,
    FullCalendarModule,
    HighchartsChartModule,
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    QRCodeModule,
    NbToggleModule,
    NbBadgeModule,
    FormsModule,
    MATERIALS,
    ReactiveFormsModule,
    MatProgressBarModule,
    NbTabsetModule,
    MatSnackBarModule,
    attendanceFrame,
    // Attendenca module Import
    NgxScannerQrcodeModule,
    NgxCaptureModule,
    NbMenuModule,
    NbAlertModule,
    MatProgressSpinnerModule,
    SHARD3RDIMPORTS,
  ],
  exports: [MatProgressBarComponent],
})
export class AttendanceModule {}
