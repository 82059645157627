import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AbstractService } from './common/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends AbstractService{

  constructor(private http:HttpClient) {
    super();
   }
  
  //user verificationon load
  public getUserProfile(): Observable<any>{
    return this.http.get<any>(`/api/users/me` ,this.httpOptions)
    .pipe(
      map(res=>{
        if(!res.success){
          localStorage.removeItem("token");
          return false;
        }
        return res.data;
      })
    );
  }
}
